import { IonButton, IonFab, IonFabButton, IonIcon, IonInput, IonPopover } from '@ionic/react';

import { close } from 'ionicons/icons';
import { ContextMenuProps } from './types';

const ContextMenu: React.FC<ContextMenuProps> = ({ isOpen, element, bounds, onClose, onDelete, onEdit }) =>
{
    const [maxY, maxX] = bounds;
    if (!element) isOpen = false;

    return <IonPopover
        trigger='event'
        style={{ "--min-height": "10%", "--min-width": "2%", }}
        isOpen={isOpen}
        onDidDismiss={onClose}
    >
        <IonFab vertical='top' horizontal='end'>
            <IonFabButton size='small' color={"danger"} onClick={() =>
            {
                if (onClose) onClose();
            }}>
                <IonIcon icon={close}></IonIcon>
            </IonFabButton>
        </IonFab>
        {element ? <section style={{ margin: "40px" }}>
            <p >{`${element.groupId}/${"0".repeat(3 - element.id!.toString().length)}${element.id} ${element.text}`}</p>


            <IonInput
                label='x'
                type='number'
                min={0}
                max={maxX}
                debounce={900}
                value={Number(element.systemPlanX).toFixed(2)}
                onIonChange={({ detail }) =>
                {
                    if (onEdit) onEdit(element, { systemPlanX: Number(detail.value) });
                }}></IonInput>
            <IonInput
                label='y'
                type='number'
                min={0}
                max={maxY}
                debounce={900}
                value={Number(element.systemPlanY).toFixed(2)}
                onIonChange={({ detail }) =>
                {
                    if (onEdit) onEdit(element, { systemPlanY: Number(detail.value) });
                }}>
            </IonInput>
            <IonButton
                color={"danger"}
                expand='full'
                onClick={() => { if (onDelete) onDelete(element); }}>
                delete
            </IonButton>
        </section> : <></>
        }
    </IonPopover>;
};

export default ContextMenu;