import { Customer, CustomerDetails, PaginatedResult, PaginationRequest } from "@ews/react-data";
import { useL10n } from "@ews/react-localization-context";
import { IonCol, IonIcon, IonItem, IonList, IonSelect, IonSelectOption, useIonRouter } from "@ionic/react";
import { caretBack, caretForward } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import FilterList from "../FilterList/FilterList";
import List from "../List/List";
import { pageRange } from "../List/Pagination/pagerange";
import { useMyProfile } from "../Login/LoginProvider";
import Sidebar from "../Navigation/Sidebar/Customer";
import { useCustomer, useFetchCustomerList } from "../ReactData/customer";
import { useIsMobile } from "../Responsive";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { Filter } from "../System/Reports/types/reportTypes";
import { CustomersListProps } from "./type";

import { Link } from "react-router-dom";
import { useNamedRoutes } from "../NamedRoutes";
import style from './CustomerList.module.scss';
import { MenuItem } from "../Navigation/Sidebar/Sidebar";
import SettingsSelect from "../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";


type ListFilter = {
    name?: string;
    parentId?: string,
    customerNumber?: number | string;
    "details.zip"?: string;
    "details.city"?: string;
    "details.country"?: string;
};

const standardFilter: Filter<ListFilter> = {
    sortCriteria: "name",
    sortOrder: "ASC"
};

const CustomerList: React.FC<CustomersListProps> = ({
    parentId,
    isFilterOpen,
    onIsOpen
}) =>
{

    const { customerId } = useMyProfile();
    const router = useIonRouter();
    parentId = parentId || customerId;


    const filterDebounceTime = 900;
    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();


    const [currentCustomers, setCurrentCustomers] = useState<Customer[]>([]);

    //const [filterDetailsOpen, setFilterDetailsOpen] = useState(true);

    const page = useRef<number | undefined>(0);
    const numberOfPages = useRef<number | undefined>(10);
    const numberOfItemsPerPage = useRef(pageRange[1]);

    const filter = useFetchCustomerList();

    const [currentState, setState] = useState<PaginationRequest & ListFilter>({});
    const parentCustomer = useCustomer(parentId || "") as Customer & CustomerDetails;

    // if (parentId) {
    //     currentState.parentId = parentId;
    // }

    async function handleFilterResponse(PaginationRequest: PaginationRequest & ListFilter)
    {
        if (Object.keys((PaginationRequest as ListFilter)).length > 0) PaginationRequest = { ...PaginationRequest, tree: parentCustomer.tree || '' };
        else PaginationRequest = { ...PaginationRequest, parentId: parentId || "" };

        const response: PaginatedResult<Customer> = await filter(PaginationRequest) as PaginatedResult<Customer>;
        const { currentPage, pageSize, results, totalCountOfPages } = response;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setCurrentCustomers(results);
    }
    useEffect(() =>
    {
        handleFilterResponse(currentState);
    }, [currentState, parentId]);

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            isDetailsHidden={false}
            onIsOpen={onIsOpen}
            isOpen={isFilterOpen}
            //onDetailOpen={setFilterDetailsOpen}
            onReset={() =>
            {
                setState({});
            }}

            details={<>
                <IonCol>
                    <SettingsTextInput
                        id="name"
                        text="CUSTOMERS_NAME"
                        debounce={filterDebounceTime}
                        value={currentState.name}

                        onChange={(name) =>
                        {
                            const currentFilter = { ...currentState };

                            if (name.length) currentFilter.name = name;
                            else delete currentFilter.name;

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        id="customerNumber"
                        text="CUSTOMERS_ID"
                        debounce={filterDebounceTime}
                        value={currentState.customerNumber?.toString()}

                        onChange={(customerNumber) =>
                        {
                            const currentFilter = { ...currentState };

                            if (customerNumber.length) currentFilter.customerNumber = customerNumber;
                            else delete currentFilter.customerNumber;

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        id="customerPostalCode"
                        text="CUSTOMERS_POSTAL_CODE"
                        debounce={filterDebounceTime}
                        value={currentState["details.zip"]?.toString()}

                        onChange={(value) =>
                        {
                            const currentFilter = { ...currentState };

                            if (value.length) currentFilter["details.zip"] = value;
                            else delete currentFilter["details.zip"];

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        id="customerCity"
                        text="CUSTOMERS_CITY"
                        debounce={filterDebounceTime}
                        value={currentState["details.city"]?.toString()}

                        onChange={(value) =>
                        {
                            const currentFilter = { ...currentState };

                            if (value.length) currentFilter["details.city"] = value;
                            else delete currentFilter["details.city"];

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>
                    <SettingsSelect
                        text={"Country"}
                        className={style.CustomerListDropdownBox}
                        value={currentState["details.country"] || "Country"}
                        onSelect={(e) =>
                        {
                            const value = e;
                            const currentFilter = { ...currentState };

                            if (value?.length) {
                                currentFilter["details.country"] = value;
                            } else {
                                delete currentFilter["details.country"];
                            }
                            setState(currentFilter);
                        }}
                    >
                        <SettingsSelectOption text={"-------"} value={""} />
                        <SettingsSelectOption text={"Austria"} value={"AT"} />
                        <SettingsSelectOption text={"Switzerland"} value={"CH"} />
                        <SettingsSelectOption text={"Germany"} value={"DE"} />
                    </SettingsSelect>
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>} >
            <IonCol></IonCol>
            <IonCol></IonCol>
            <IonCol></IonCol>
        </FilterList >

        {(parentId && parentId !== customerId) &&
            <IonItem>
                <Link to={parentCustomer.parentId ? generate("customer:customers", { id: parentCustomer.parentId }) : generate("customers")}><IonIcon icon={caretBack} /> {t("Zurück")}</Link>&nbsp;|&nbsp;
                <span>{[parentCustomer.name, parentCustomer.name2, parentCustomer.name3].join(", ")}</span>
            </IonItem>
        }


        < List
            rows={currentCustomers}
            rowClass={`${style.row} ${mobileView ? style.mobile : ''}`}
            sort={currentState.sortCriteria ? {
                key: currentState.sortCriteria,
                direction: currentState.sortOrder || "ASC"
            } : undefined}

            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
            onPageChange={(page) => setState({ ...currentState, page })}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}

            /* maxHeight={mobileView ? undefined : '50vh'} */
            link={{ url: "customer", paramsKey: "id" }}
            onMenu={(id: string, onMenuToggle: () => void) => <Sidebar onMenuToggle={onMenuToggle} routeParams={{ id }} />}
            headers={
                [

                    /*      */
                    {
                        title: '',
                        key: 'children',
                        className: `${style.childIndicator} ${mobileView ? style.mobile : ''}`,
                        child: (row) =>
                        {
                            if (!row.children) return <></>;

                            const link = generate("customer:customers", { id: row.id });
                            return <div onClick={(e) =>
                            {
                                e.preventDefault(); router.push(link);
                            }}> <IonIcon icon={caretForward} /></div >;
                        },
                        both: true,
                    },
                    {
                        title: 'CUSTOMERS_ID',
                        key: 'customerNumber',
                        both: true,
                    },
                    {
                        title: 'CUSTOMERS_NAME',
                        key: 'name',
                        both: true,

                    },
                    {
                        title: 'CUSTOMERS_NAME2',
                        key: 'name2',
                        breakpoint: 1500, up: true,
                        subEntity: 'details.name2'
                    },
                    {
                        title: 'CUSTOMERS_NAME3',
                        key: 'name3',
                        breakpoint: 1900, up: true,
                        subEntity: 'details.name3'
                    },
                    {
                        title: 'CUSTOMERS_ADDRESS1',
                        key: 'street',
                        both: true,
                        subEntity: 'details.street'
                    },
                    {
                        title: 'CUSTOMERS_ADDRESS2',
                        key: 'street2',
                        breakpoint: 1900, up: true,
                        subEntity: 'details.street2'
                    },
                    {
                        title: 'CUSTOMERS_POSTAL_CODE',
                        key: 'zip',
                        breakpoint: 1200, up: true,
                        subEntity: 'details.zip'
                    },
                    {
                        title: 'CUSTOMERS_CITY',
                        key: 'city',
                        breakpoint: 1200, up: true,
                        subEntity: 'details.city'
                    },
                    {
                        title: 'CUSTOMERS_COUNTRY',
                        key: 'country',
                        breakpoint: 1500, up: true,
                        subEntity: 'details.country'
                    },

                    /*    {
                           title: 'Land',
                           key: 'land',
                           breakpoint: 1500, up: true,
                             }, */
                    /*     {
                            title: 'created',
                            key: 'created',
                            both: true,
                              }, */
                ]}

        />

    </div >;
};

export default CustomerList;
