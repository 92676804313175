import { CRS, LatLngBoundsExpression, Map as MapType } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import style from "./Map.module.scss";


import { useEffect, useRef, useState } from 'react';
import { ImageOverlay, MapContainer } from 'react-leaflet';
import Marker from '../Element/Element';
import { EventClick, EventZoom } from '../Event/Event';
import { Coords, MapProps } from './types';


import { SystemElement } from '../../../ReactData/system';
import { useIsMobile } from '../../../Responsive';
import ContextMenu from './components/ContextMenu/ContextMenu';


function getMinZoom(width: number, height: number)
{
    const crsSimpleDefaultSize = 1000;
    if (width > crsSimpleDefaultSize || height > crsSimpleDefaultSize)
        if (width > height) {
            return -(Math.round(width / crsSimpleDefaultSize));
        }
        else if (width < height) {
            return -(Math.round(height / crsSimpleDefaultSize));
        }
    return 0;
}

const Map: React.FC<MapProps>
    = ({
        url,
        elementList,
        zoom = -2,
        onClick,
        onDelete,
        onSetPosition,
        onEdit,
        onZoom
    }) =>
    {
        const mobileView = useIsMobile();
        const [isLoaded, setIsLoaded] = useState<boolean>(false);
        const [element, setElement] = useState<SystemElement | null>(null);
        const width = useRef<number>(0);
        const height = useRef<number>(0);
        const mapRef = useRef<MapType | null>(null);
        const [_zoom, setZoom] = useState<number>(zoom);
        const [bounds, setBounds] = useState<LatLngBoundsExpression>([[0, 0]]);
        const [minZoom, setMinZoom] = useState<number>(0);

        function renderMap()
        {
            setIsLoaded(true);
        }

        useEffect(() =>
        {
            const img = new Image();
            img.src = url;
            img.onload = () =>
            {
                width.current = img.width;
                height.current = img.height;

                const bounds = [[0, 0], [img.height, img.width]] as LatLngBoundsExpression;
                const minZoom = getMinZoom(img.width, img.height);

                mapRef.current?.setZoom(minZoom);
                mapRef.current?.fitBounds(bounds);

                setBounds(bounds);
                setZoom(minZoom);
                setMinZoom(minZoom);
                renderMap();
            };
        }, [url]);

        function handleClick(coords: Coords)
        {
            const { systemPlanX, systemPlanY } = coords;

            onSetPosition({ systemPlanX, systemPlanY });
        }

        // CRS.Simple take the form of [y (height), x (width)] instead of [x, y], in the same way Leaflet uses [lat, lng] instead of [lng, lat].

        return (isLoaded ?
            <>
                <MapContainer
                    attributionControl={false}
                    className={style.leafletContainer}
                    zoomAnimation={false}
                    markerZoomAnimation={false}
                    center={[
                        height.current / 2,
                        width.current / 2
                    ]}
                    ref={mapRef}
                    minZoom={minZoom}
                    maxZoom={5}
                    zoom={_zoom}
                    doubleClickZoom={false}
                    crs={CRS.Simple}
                >
                    <ImageOverlay
                        url={url}
                        bounds={bounds}
                        alt='plan'
                        zIndex={10}
                    />
                    {elementList.map((item, i) =>
                    {
                        return <Marker
                            key={i}
                            iconSize={50}
                            labelSize={50 / 2}
                            zoom={_zoom}
                            element={item}
                            onContextMenu={(_element, event) => setElement(_element)}
                        />;
                    })}
                    <EventClick onClick={handleClick} />
                    <EventZoom onZoom={(e) =>
                    {
                        const zoom = mapRef.current?.getZoom();
                        if (zoom) {
                            if (onZoom) {
                                onZoom(zoom);
                            }
                            else {
                                setZoom(zoom);
                            }
                        };
                    }} />
                </MapContainer>

                <ContextMenu
                    bounds={[height.current, width.current]}
                    element={element}
                    isOpen={Boolean(element)}
                    onClose={() => setElement(null)}
                    onDelete={(element) =>
                    {
                        setElement(null);
                        if (onDelete) onDelete(element);
                    }}
                    onEdit={onEdit}
                />

            </>
            : null
        );
    };

export default Map;
