import { useNamedRoutes } from "../../../NamedRoutes";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import { PermissionsSettingsProps } from "./types";

import
{
    useCustomerPermissions,
    useModifyCustomerPermissions
} from "../../../ReactData/customer";

import
{
    CustomerPermissions,
    permissionAllowed,
    permissionAvailable,
    permissionChecked
} from "@ews/react-data";

const PermissionsSettings: React.FC<PermissionsSettingsProps> = ({ customer, }) =>
{
    const { params } = useNamedRoutes();
    const { id } = params();


    const permission = useCustomerPermissions(customer.id || id!);
    const modifyCustomerPermissions = useModifyCustomerPermissions();

    //console.log(permission);

    function saveCustomerPermission(key: keyof CustomerPermissions, value: any)
    {
        if (!customer.id || !(key in permission)) return;
        value = value ? 1 : 0;
        permission[key] = value;
        modifyCustomerPermissions(customer.id, { [key]: value } as CustomerPermissions);
    }

    return <>
        <SettingsCol>
            <SettingsCheckbox


                text={"is allowed to create new users"}
                disabled={!permissionAvailable(permission.createUser!)}
                checked={permissionAllowed(permission.createUser!)}
                onChange={(checked) =>
                {

                    saveCustomerPermission("createUser", checked);
                }} />
            <SettingsCheckbox

                text={"is allowed to create new clients"}
                disabled={!permissionAvailable(permission.createCustomer!)}
                checked={permissionAllowed(permission.createCustomer!)}
                onChange={(checked) =>
                {

                    saveCustomerPermission("createCustomer", checked);
                }} />
            <SettingsCheckbox

                text={"Can view all visible systems"}
                disabled={!permissionAvailable(permission.listAllSystems!)}
                checked={permissionAllowed(permission.listAllSystems!)}
                onChange={(checked) =>
                {

                    saveCustomerPermission("listAllSystems", checked);
                }} />
            <SettingsCheckbox

                text={"is allowed to create and connect systems"}
                disabled={!permissionAvailable(permission.grantSystemAccess!)}
                checked={permissionAllowed(permission.grantSystemAccess!)}
                onChange={(checked) =>
                {

                    saveCustomerPermission("grantSystemAccess", checked);
                }} />
            <SettingsCheckbox
                text={"is allowed to add new enablements"}
                disabled={!permissionAvailable(permission.createShare!)}
                checked={permissionAllowed(permission.createShare!)}
                onChange={(checked) =>
                {
                    saveCustomerPermission("createShare", checked);
                }} />

        </SettingsCol>
        <SettingsCol>
            <SettingsCheckbox

                text={"Authorization level2 allowed"}
                disabled={!permissionAvailable(permission.permitAccessLevel2!)}
                checked={permissionAllowed(permission.permitAccessLevel2!)}
                onChange={(checked) =>
                {

                    saveCustomerPermission("permitAccessLevel2", checked);
                    // setShowOperationOnlyButton(value);
                }} />
            <SettingsCheckbox

                text={"Authorization level3 allowed"}
                disabled={!permissionAvailable(permission.permitAccessLevel3!)}
                checked={permissionAllowed(permission.permitAccessLevel3!)}
                onChange={(checked) =>
                {
                    //console.log(checked);
                    saveCustomerPermission("permitAccessLevel3", checked);
                }} />
            <SettingsCheckbox

                text={"Resives notifications of lower level systems"}
                disabled={!permissionAvailable(permission.notifyAllSystem!)}
                checked={permissionAllowed(permission.notifyAllSystem!)}
                onChange={(checked) =>
                {
                    saveCustomerPermission("notifyAllSystem", checked);
                }} />
        </SettingsCol>
        <SettingsCol>
            <SettingsCheckbox
                text={"Operation only possible if session is enabled on the FDCP"}
                /* disabled={permissionAvailable(permission.restrictOperationToApprovedSession!)} */
                checked={permissionChecked(permission.restrictOperationToApprovedSession!)}
                onChange={(checked) =>
                {
                    saveCustomerPermission("restrictOperationToApprovedSession", checked);
                }} />
            <SettingsCheckbox

                text={"Hide clients,user and systems of lower level"}

                checked={permission.hideTreeBelow!}
                onChange={(checked) =>
                {
                    saveCustomerPermission("hideTreeBelow", checked);
                }} />
            <SettingsCheckbox
                text={"Sub-Admin"}
                disabled={!permissionAvailable(permission.isAdmin!)}
                checked={permissionAllowed(permission.isAdmin!)}
                onChange={(checked) =>
                {
                    saveCustomerPermission("isAdmin", checked);
                }} />
            <SettingsCheckbox
                text={"Resseler"}
                disabled={!permissionAvailable(permission.isReseller!)}
                checked={permissionAllowed(permission.isReseller!)}
                onChange={(checked) =>
                {
                    saveCustomerPermission("isReseller", checked);
                }} />

        </SettingsCol>

    </>;
};

export default PermissionsSettings;