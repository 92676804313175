import { IonAlert, IonButton, IonCol, IonGrid, IonInput, IonLabel, IonNote, IonRow } from "@ionic/react";
import { useFetchSystemList } from "../ReactData/system";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { useState } from "react";
import { useL10n } from "@ews/react-localization-context";
import { useNamedRoutes } from "../NamedRoutes";


const SystemSelect = () =>
{
    const { translate: t } = useL10n();
    const { generate, router } = useNamedRoutes();
    const findSystem = useFetchSystemList();
    const [systemNumber, setSystemNumber] = useState("");
    const [error, setError] = useState("");

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <IonGrid className="ion-padding">
            <IonRow>
                <IonCol>
                    <SettingsTextInput
                        id="system-number"
                        text="Enter system number"
                        value={systemNumber}
                        onChange={(systemNumber) =>
                        {
                            setSystemNumber(systemNumber);
                        }}
                    />
                </IonCol>
                <IonCol style={{ display: 'flex', flexDirection: "column", alignItems: "end", justifyContent: "end" }}>
                    <IonButton
                        color={"success"}
                        onClick={async () =>
                        {

                            const { results: systems } = await findSystem({ systemNumber, pageSize: 2 });
                            console.log(systems);
                            if (!systems || systems.length !== 1 || parseInt(systems[0].systemNumber as unknown as string) !== parseInt(systemNumber)) {
                                setError(t("Could not find system with system number '%{systemNumber}'", { systemNumber }));
                            } else {
                                const system = systems[0];
                                const route = generate("system:events", { id: system.id! });
                                setError("");
                                router?.push(route);
                            }

                        }}
                    >
                        <span> {t('Operate system')}</span>
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol><IonNote color="danger">{error}</IonNote></IonCol>
            </IonRow>
        </IonGrid>
    </div>;
};

export default SystemSelect;