import { useL10n } from "@ews/react-localization-context";
import { FC } from "react";
import
{
    Navbar,
    NavbarLink
} from "./Navbar";

import
{
    apps,
    informationCircleSharp,
    map,
    menu
} from "ionicons/icons";
import { iif } from "../../../utils";
import { useNamedRoutes } from "../../NamedRoutes";
import { useIsMobile } from "../../Responsive";
import { MenuToggleProps } from "../types";
import { useMyPermissions } from "../../Login/LoginProvider";
import { permissionAllowed } from "@ews/react-data";
import { useAuthorization } from "../../Authorization";
import { AuthorizationLevel } from "@ews/zlt-events";

const System: FC<MenuToggleProps> = ({ onMenuToggle }) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();

    const isMobile = useIsMobile();
    const permissions = useMyPermissions();
    const { authorizationLevel } = useAuthorization();

    if (!("listViewAccessLevel1" in permissions)) return <></>;

    const color = isMobile ? 'secondary' : 'light';

    return (
        <Navbar
            onMenuToggle={onMenuToggle}
            color={color}
        >
            <NavbarLink
                /* show={!isMobile} */
                icon={iif(isMobile, informationCircleSharp)}
                title={t('SYSTEM_DETAILS')}
                link={generate('system')}
            />
            <NavbarLink
                icon={iif(isMobile, apps)}
                title={t('[SID_MNU_HK_EVENTS]')}
                link={generate('system:events')}
            />
            <NavbarLink
                show={permissions.listViewAccessLevel1! || (authorizationLevel > AuthorizationLevel.LEVEL1)}
                icon={iif(isMobile, menu)}
                title={t('Elements')}
                link={generate('system:elements')}
            />
            <NavbarLink
                icon={iif(isMobile, map)}
                title={t('Plan')}
                link={generate('system:plan')}
            />
        </Navbar>
    );

};

export default System;