import { LeafletEvent } from "leaflet";
import { useMapEvent } from "react-leaflet";
import { ClickProps, ZoomProps } from "./types";

export function EventClick({ onClick }: ClickProps)
{
    useMapEvent('click', (e) =>
    {
        //Hinweis: [lat, lng] = [y, x]
        if (onClick) onClick({ systemPlanY: e.latlng.lng, systemPlanX: e.latlng.lat });
    });

    return null;
}

export function EventZoom({ onZoom }: ZoomProps)
{
    useMapEvent('zoom', (e: LeafletEvent) =>
    {
        if (onZoom) onZoom(e);
    });

    return null;
} 