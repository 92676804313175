import { useL10n } from "@ews/react-localization-context";
import { SystemPlan, UserPermissions } from "@ews/react-data";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton } from "@ionic/react";
import { add } from "ionicons/icons";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import { useIsMobile } from "../../../Responsive";
import NewPlanPageModal from "../NewPlanPage/NewPlanPage";
import style from "./Floors.module.scss";
import { Props } from "./types";
import { useMyPermissions } from "../../../Login/LoginProvider";
import { useAuthorization } from "../../../Authorization";
import { AuthorizationLevel } from "@ews/zlt-events";



export function loadImageBounds(src: string | null, onLoad: (size: [number, number]) => void)
{
    const img = new Image();
    img.src = src || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
    img.onload = () => onLoad([img.height, img.width]);//
}

const Floors: React.FC<Props> = ({
    systemId,
    floors,
    current,
    onChange,
    onContext
}) =>
{
    const { authorizationLevel } = useAuthorization();
    const [isNewPlanOpen, setIsNewPlanOpen] = useState(false);

    const { translate: t } = useL10n();
    const mobileView = useIsMobile();

    const sortedFloors = [...floors].sort((a, b) => a.floor! - b.floor!);

    function clickHandler(floor: SystemPlan)
    {
        if (onChange) {
            loadImageBounds(floor.image!, (size) => onChange(floor, size));
        };
    }

    function highlightEffect(floor: SystemPlan)
    {
        return current && floor.floor === current.floor ? "primary" : undefined;
    }

    return mobileView ?
        <IonSegment color={"dark"} scrollable>
            {sortedFloors.map((floor, index) =>
            {
                return <IonSegmentButton
                    //color={current && floor.floor === current.floor ? "secondary" : undefined}
                    key={index}
                    value={floor.name}
                    color={highlightEffect(floor)}
                    onClick={() =>
                    {
                        clickHandler(floor);
                    }}>
                    <IonLabel>{floor.label}</IonLabel>
                </IonSegmentButton>;
            })}
        </IonSegment>
        :
        <>
            <IonCard>

                <IonCardHeader color={"dark"}>
                    <IonItem color={"dark"}>
                        <IonCardTitle className={style.textCenter}>{t("Floors")}</IonCardTitle>
                        {authorizationLevel === AuthorizationLevel.LEVEL3 ? <IonButton
                            slot="end"
                            color={"success"}
                            onClick={() =>
                            {
                                setIsNewPlanOpen(true);
                            }}
                        >
                            <IonIcon icon={add} />
                        </IonButton> : null}
                    </IonItem>
                </IonCardHeader>

                <IonCardContent className={style.content}>
                    <IonList>
                        {sortedFloors.map((floor, index) =>
                        {
                            return <IonItem
                                key={index}
                                color={highlightEffect(floor)}
                                className={style.floor}
                                onClick={() =>
                                {
                                    clickHandler(floor);
                                }}
                                onContextMenu={(e) =>
                                {
                                    e.preventDefault();
                                    if (onContext) onContext(floor);
                                }}
                            >
                                {floor.label}
                            </IonItem>;
                        })}
                    </IonList>
                </IonCardContent>
            </IonCard>

            {authorizationLevel === AuthorizationLevel.LEVEL3 ? <NewPlanPageModal
                isOpen={isNewPlanOpen}
                systemId={systemId || ""}
                list={floors}
                onDismiss={(isOpen) =>
                {
                    setIsNewPlanOpen(isOpen);
                }}
            /> : null}
        </>;
};

export default Floors;