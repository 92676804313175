import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import
{
    ReactNode,
    createContext,
    useContext,
    useState
} from "react";




export type GoogleMapScriptContextType = { isLoaded: boolean, loadError: Error | undefined; };


const GoogleMapScriptContext = createContext<GoogleMapScriptContextType>({ isLoaded: false, loadError: undefined });


export const useGoogleMapScriptLoader = () =>
{
    return useContext(GoogleMapScriptContext);
};

const libraries: Libraries = ["core", "drawing", "places", "marker"];

export const GoogleMapScriptLoader: React.FC<{ children: ReactNode; }> = ({ children }) =>
{
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        libraries,
        googleMapsApiKey: 'AIzaSyD4KU0gz-fO4hS-BhXUDjy5zYIuAVFHKPI',
    });

    return (
        <GoogleMapScriptContext.Provider value={{ isLoaded, loadError }}>
            {children}
        </GoogleMapScriptContext.Provider >
    );
};