import { SortedRequest } from "@ews/react-data";
import { IonButton, IonCol, IonLabel, IonRow } from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import FilterList from "../../../FilterList/FilterList";
import List from "../../../List/List";
import { useNamedRoutes } from "../../../NamedRoutes";
import { MaintenanceLogItem, useCurrentSystem, useReportSystemMaintenanceLog } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import DatePicker, { dateRange, dateStringToValue, valueToDateString } from "../../../Settings/SettingsComponents/SettingsRangeInput/components/DatePicker/DatePicker";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { MaintenanceReportFilterKeys, MaintenanceReportProps } from "./types";
import style from "./MaintenanceReport.module.scss";
import { eventDetails, formatLogicalAddress, timestampToDate } from "@ews/zlt-events";
import { useL10n } from "@ews/react-localization-context";
import { useMyDateFormat } from "../../../Login/LoginProvider";

import jsonToCsvExport from "json-to-csv-export";
import { useCurrentSystemStatus } from "../../SystemStatus";

const defaultRange = dateRange(new Date(), 1);

const MaintenanceReport: React.FC<MaintenanceReportProps> = ({ system }) =>
{
    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const dateFormat = useMyDateFormat();
    const filterData = useReportSystemMaintenanceLog();

    const [list, setList] = useState<MaintenanceLogItem[]>([]);

    const [filterLog, setFilterLog] = useState<MaintenanceReportFilterKeys & SortedRequest>({
        timestamp: valueToDateString(defaultRange),
        status: ""
    });

    const getFilteredData = useCallback(async (id: string, query: SortedRequest) =>
    {
        const response = await filterData(id, query);
        if (!response) return;

        setList(response);

    }, [filterData]);

    useEffect(() =>
    {
        if (system.id) getFilteredData(system.id, filterLog);
    }, [filterLog, system/* , getFilteredData */]);

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            isDetailsHidden={false}

            details={<>
                <IonCol size="3">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("REPORT_MAINTENANCE_LOG_ELEMENTS")}</IonLabel>
                    </div>
                    <SettingsSelect text={""} value={filterLog.status} onSelect={(value) =>
                    {
                        const currentFilter = { ...filterLog };
                        currentFilter.status = value;
                        setFilterLog({ ...currentFilter });
                    }}>
                        <SettingsSelectOption text={"all"} value={''}></SettingsSelectOption>
                        <SettingsSelectOption text={"REPORT_MAINTENANCE_LOG_ELEMENTS_TESTED"} value={'tested'}></SettingsSelectOption>
                        <SettingsSelectOption text={"REPORT_MAINTENANCE_LOG_ELEMENTS_UNTESTED"} value={'untested'}></SettingsSelectOption>

                    </SettingsSelect>
                </IonCol>
                <IonCol size="3">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("REPORT_MAINTENANCE_LOG_DATE")}</IonLabel>
                    </div>
                    <DatePicker

                        childText={{ start: "start", end: "end" }}
                        value={dateStringToValue(filterLog.timestamp!)}
                        divider={false}
                        position="row"
                        onChange={(range) =>
                        {
                            const currentFilter = { ...filterLog };
                            currentFilter.timestamp = valueToDateString(range);
                            setFilterLog({ ...currentFilter });
                        }}

                    />
                </IonCol>
                <IonCol style={{ display: 'flex', flexDirection: "column", alignItems: "end" }}>
                    <IonRow >
                        <IonButton
                            color={"success"}
                            onClick={async () =>
                            {
                                jsonToCsvExport({
                                    data: list.map(item =>
                                    {
                                        return {
                                            'logicalAddress': formatLogicalAddress(item.zone, item.element),
                                            'eventKindType': item.eventKindType ? t(eventDetails(item.eventKindType)?.translationKey || "unknown") : "",
                                            'elementText': item.elementText,
                                            'groupText': item.groupText,
                                            'timestamp': item.timestamp ? dateFormat(timestampToDate(item.timestamp), "UTC") : ""
                                        };
                                    }),
                                    delimiter: ";",
                                    filename: `${t('Wartungsbericht')}_${system.systemNumber}_${Date.now()}`,
                                    headers: [
                                        { key: 'logicalAddress', label: t('Element') },
                                        { key: 'eventKindType', label: t('REPORT_MAINTENANCE_LOG_EVENT') },
                                        { key: 'elementText', label: t('Text1') },
                                        { key: 'groupText', label: t('Text2') },
                                        { key: 'timestamp', label: t('REPORT_MAINTENANCE_LOG_DATE') }
                                    ]
                                });
                            }}
                        >
                            <span> {`${t('Download CSV')}`}</span>
                        </IonButton>
                    </IonRow>
                </IonCol>
            </>}
        >

        </FilterList >


        <List
            rows={list}
            noLink
            noPagination
            sort={
                filterLog.sortCriteria ? {
                    key: filterLog.sortCriteria,
                    direction: filterLog.sortOrder || "ASC"
                } : undefined
            }
            onSort={(sortCriteria, sortOrder) => setFilterLog({ ...filterLog, sortCriteria, sortOrder })}
            headers={[
                {
                    title: 'Element', className: `${style["col"]} ${mobileView ? style["sizeFull"] : style["size2"]}`, key: 'zone,element', both: true, child: ({ element, zone }) =>
                    {
                        return <>{formatLogicalAddress(zone, element)}</>;

                    }
                },
                {
                    title: 'REPORT_MAINTENANCE_LOG_EVENT', className: `${style["col"]} ${mobileView ? style["sizeFull"] : style["size4"]}`, key: 'eventKindType', both: true, child: ({ eventKindType }) =>
                    {

                        return <>
                            <div>
                                {eventKindType ? t(eventDetails(eventKindType)?.translationKey || "unknown") : ""}
                            </div>
                        </>;
                    }
                },
                { title: 'Text 1', key: 'elementText', both: true, className: `${style["col"]}` },
                { title: 'Text 2', key: 'groupText', both: true, className: `${style["col"]}` },
                {
                    title: 'REPORT_MAINTENANCE_LOG_DATE', className: `${style["col"]} ${mobileView ? style["sizeFull"] : style["size3"]}`, key: 'timestamp', both: true, child: ({ timestamp }) =>
                    {
                        return <>

                            {timestamp ? dateFormat(timestampToDate(timestamp), "UTC") : ""}
                        </>;
                    }
                },
            ]}

        />
    </div>;
};

export default MaintenanceReport;