import
{
  IonReactRouter
} from '@ionic/react-router';
import "./global.scss";

import React, {
  ReactNode
} from 'react';

import
{
  useParams
} from 'react-router';

import
{
  IonApp, setupIonicReact
} from '@ionic/react';

import
{
  NamedRoute,
  Redirect
} from './components/NamedRoutes';

import { } from '@ews/react-auth-context';

import Navigation from './components/Navigation';

import Home from './pages/Home';

import
{
  System,
  SystemAnalogueValues,
  SystemDetails,
  SystemDisplayDevice,
  SystemDocumentationHistory,
  SystemDocumentationsSettingsOverlay,
  SystemEvent,
  SystemEventMemory,
  SystemGroup,
  SystemInputsSystemDocumentation,
  SystemMaintenanceReport,
  SystemOtherAlarmDevices,
  SystemPhonenumber,
  SystemPlan,
  SystemPowerUnitsDocumentation,
  //SystemQueries,
  SystemShares,
  SystemShareSettings,
  SystemSettings,
  SystemTypeDefinitionSubmissionDocuments
} from './pages/System';

import
{
  User,
  UserSettings
} from './pages/User';

import
{
  L10n,
  L10nProvider,
  Locales
} from './setup/locale';

import './setup/routes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { ResponsiveProvider } from './components/Responsive';
import './theme/variables.css';

import Navbar from './components/Navigation/Navbar/Main';
import Sidebar from './components/Navigation/Sidebar/Main';

import
{
  ToolTipProvider
} from './components/ToolTip/useTooltip';

import
{
  ReactData
} from './components/ReactData/ReactDataProvider';


import
{
  LoadingIndicator
} from './components/Loading';

import
{
  Customer,
  CustomerAddCustomer,
  CustomerAddUser,
  CustomerSettings
} from './pages/Customer';

import LoginProvider, {
  AuthInvalid,
  AuthValid
} from './components/Login/LoginProvider';

import
{
  Authorization
} from './components/Authorization';

import LoginPage, {
  ForgotPasswordPage,
  ResetPasswordPage
} from './components/Login/LoginPage';

import DeepLink from './components/DeepLink/Deeplink';

import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';
import { GoogleMapScriptLoader } from './components/GoogleScriptLoader';


setupIonicReact();

const TranslationWrapper = ({ children }: { children: ReactNode; }) =>
{
  const params = useParams<{ locale?: string; }>();
  L10nProvider.setLocale(params.locale || Locales.default);

  return (
    <L10n provider={L10nProvider} autoCapture={false} >
      {children}
    </L10n>
  );
};

const NavigationWrapper = ({ children }: { children: ReactNode; }) =>
{
  return (
    <Navigation navbar={Navbar} sidebar={Sidebar} id="main">
      {children}
    </Navigation>

  );
};

const PageWrapper = ({ children }: { children: ReactNode; }) =>
{

  const params = useParams<{ locale?: string; }>();
  L10nProvider.setLocale(params.locale || Locales.default);

  return (

    <TranslationWrapper>
      <NavigationWrapper>
        {children}
      </NavigationWrapper>
    </TranslationWrapper>
  );
};

const AuthComponentWrapper = ({ children }: { children?: ReactNode; }) =>
{
  const { component, rest } = useParams<{ component?: string; rest?: string; }>();

  switch (component) {
    case 'forgot-password':
      return <TranslationWrapper>
        <ForgotPasswordPage />
      </TranslationWrapper>;

    case 'reset-password':
      return <TranslationWrapper>
        <ResetPasswordPage token={rest} />
      </TranslationWrapper>;

    default:

      return <TranslationWrapper>
        <LoginPage />
      </TranslationWrapper>;
  }

};

const App: React.FC = () =>
{
  return (

    <IonApp>

      <ReactData>



        <ResponsiveProvider>

          {/* <NetworkStatus></NetworkStatus> */}
          {/* <AuthorizationStatus></AuthorizationStatus> */}
          <LoadingIndicator>
            <ToolTipProvider>
              <IonReactRouter>

                <DeepLink />

                <LoginProvider>
                  <AuthInvalid>

                    <NamedRoute name="auth-component">
                      <AuthComponentWrapper />
                    </NamedRoute>

                  </AuthInvalid>
                  <AuthValid>

                    <Authorization>

                      <GoogleMapScriptLoader>

                        <NamedRoute name="home-default">
                          <Redirect to="/" />
                        </NamedRoute>
                        <NamedRoute name="home">
                          <PageWrapper>
                            <Home />
                          </PageWrapper>
                        </NamedRoute>

                        <NamedRoute name="systems" >
                          <TranslationWrapper>
                            <System />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:events" >
                          <TranslationWrapper>
                            <SystemEvent />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system" >
                          <TranslationWrapper>
                            <SystemDetails />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:plan" >
                          <TranslationWrapper>
                            <SystemPlan />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:settings" >
                          <TranslationWrapper>
                            <SystemSettings />
                          </TranslationWrapper>
                        </NamedRoute>


                        <NamedRoute name="system:elements" >
                          <TranslationWrapper>
                            <SystemGroup />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:maintenancereport" >
                          <TranslationWrapper>
                            <SystemMaintenanceReport />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:eventmemory" >
                          <TranslationWrapper>
                            <SystemEventMemory />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:analoguevalues" >
                          <TranslationWrapper>
                            <SystemAnalogueValues />
                          </TranslationWrapper>
                        </NamedRoute>

                        {/* <NamedRoute name="system:queries" >
                      <TranslationWrapper>
                        <SystemQueries />
                      </TranslationWrapper>
                    </NamedRoute> */}

                        <NamedRoute name="system:shares" >
                          <TranslationWrapper>
                            <SystemShares />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:share-settings" >
                          <TranslationWrapper>
                            <SystemShareSettings />
                          </TranslationWrapper>
                        </NamedRoute>


                        <NamedRoute name="system:phonenumber" >
                          <TranslationWrapper>
                            <SystemPhonenumber />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:Documentationhistory" >
                          <TranslationWrapper>
                            <SystemDocumentationHistory />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:displaydevice" >
                          <TranslationWrapper>
                            <SystemDisplayDevice />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:powerunitsDocumentation" >
                          <TranslationWrapper>
                            <SystemPowerUnitsDocumentation />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:otheralarmdevices" >
                          <TranslationWrapper>
                            <SystemOtherAlarmDevices />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:otherdevices" >
                          <TranslationWrapper>
                            <SystemOtherAlarmDevices />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:inputssystemDocumentation" >
                          <TranslationWrapper>
                            <SystemInputsSystemDocumentation />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:submissiondocuments" >
                          <TranslationWrapper>
                            <SystemDocumentationsSettingsOverlay />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:typedefinitionsubmissiondocuments" >
                          <TranslationWrapper>
                            <SystemTypeDefinitionSubmissionDocuments />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="users">
                          <TranslationWrapper>
                            <User />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="user" >
                          <TranslationWrapper>
                            <UserSettings />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customers">
                          <TranslationWrapper>
                            <Customer />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customer:customers">
                          <TranslationWrapper>
                            <Customer filterByCustomer />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customer" >
                          <TranslationWrapper>
                            <CustomerSettings />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customer:addcustomer" >
                          <TranslationWrapper>
                            <CustomerAddCustomer />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customer:adduser" >
                          <TranslationWrapper>
                            <CustomerAddUser />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="system:addsystem" >
                          <TranslationWrapper>
                            <SystemSettings />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customer:systems" >
                          <TranslationWrapper>
                            <System filterByCustomer />
                          </TranslationWrapper>
                        </NamedRoute>

                        <NamedRoute name="customer:users" >
                          <TranslationWrapper>
                            <User filterByCustomer />
                          </TranslationWrapper>
                        </NamedRoute>


                      </GoogleMapScriptLoader>
                    </Authorization>

                  </AuthValid>
                </LoginProvider>

              </IonReactRouter>
            </ToolTipProvider>
          </LoadingIndicator>
        </ResponsiveProvider>


      </ReactData>
    </IonApp >
  );
};

export default App;
