
import Icon from "@ews/react-icons";
import { NoDeviceNumber, eventGetSymbol, formatLogicalAddress } from "@ews/zlt-events";
import { IonItem } from "@ionic/react";
import { DivIcon, Icon as Licon } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { Marker, Popup } from 'react-leaflet';
import { Devices, deviceList } from "../../deviceList";
import style from "./Element.module.scss";
import { ElementProps } from "./types";


export function getDeviceIconsFilePath(file: string)
{
    return `assets/device-icons/${file}.png`;
}

const Element: React.FC<ElementProps> = ({
    element,
    onContextMenu,
    zoom,
    iconSize = 25,
    labelSize = 12.5
}) =>
{

    function checkImage(
        path: string,
        onload: (path: string) => void,
        onerror: (e: string | Event) => void
    )
    {
        const img = new Image();
        img.loading = "lazy";
        img.alt = "";
        img.height = 0;
        img.width = 0;
        document.body.append(img);

        img.onload = () =>
        {
            img.hidden = true;
            onload(path);
        };
        img.onerror = onerror;
        img.src = path;
    }

    const { systemPlanX, systemPlanY, systemPlanIcon, text, groupId, id, rtm, eventKindType } = element;

    const logicalAddress = `${formatLogicalAddress(groupId!, id || NoDeviceNumber, 1)} ${text}`;

    const scale = getScaleSize(zoom || 0, iconSize);


    function getScaleSize(zoom: number, originSize: number, factor: number = 1)
    {
        let result = originSize;

        if (zoom > 0) {
            result = originSize * (zoom + factor);
        }
        else {
            result = originSize / (Math.abs(zoom) + factor);
        };
        return result;
    }

    const lIcon = new Licon({
        iconUrl: getDeviceIconsFilePath("question"),
        iconSize: [iconSize, iconSize],
    });

    const [icon, setIcon] = useState<Licon>(lIcon);

    function _setIcon(path: string,)
    {
        let _eventKindType = eventKindType || 0;

        checkImage(path, (_path) =>
        {
            icon.options.iconUrl = _path;
            icon.options.iconSize = [scale, scale];

            if ((deviceList[systemPlanIcon as keyof Devices][_eventKindType])) {
                const deviceEvent = deviceList[systemPlanIcon as keyof Devices][_eventKindType];
                icon.options.className = deviceEvent.blink ? style.blink : "";
            }

            setIcon(new Licon({ ...icon.options }));
        },
            (error) =>
            {
                icon.options.iconUrl = getDeviceIconsFilePath("question");
                icon.options.iconSize = [scale, scale];
                icon.options.className = "";
                setIcon(new Licon({ ...icon.options }));
            }
        );

    }

    useEffect(() =>
    {
        if (systemPlanIcon) {
            const path = eventKindType ?
                getDeviceIconsFilePath(`${""}${systemPlanIcon}_${eventKindType}`) :
                getDeviceIconsFilePath(`${""}${systemPlanIcon}_${0}`);
            _setIcon(path);
        }

        return () => { };
    }, [eventKindType, zoom]);

    const labelOffsetX = (40);
    const labelOffsetY = (5 * logicalAddress.length);

    const dIcon = new DivIcon({
        className: style.divIcon,
        html: `
        <section 
        class="${style.label}"
        style="font-size:${getScaleSize(zoom || 0, labelSize)}px;"
        >
            ${logicalAddress}
        </section > `,
    });

    return <>
        <Marker
            eventHandlers={{
                contextmenu: (e) =>
                {
                    if (onContextMenu) onContextMenu(element, e);
                }
            }}
            position={[systemPlanX!, systemPlanY!]}
            icon={icon}
        >
            <Popup >
                <section className={style.popover}>
                    <IonItem>
                        <div className={style.center}>{text}</div>
                    </IonItem>
                    <IonItem>
                        <div className={style.center}>{logicalAddress}</div>
                    </IonItem>
                    {
                        rtm && rtm.length ? <IonItem>
                            <div className={style.center}>{
                                rtm?.map(event =>
                                {
                                    const { zone, element, eventKindType } = event;
                                    const icon = eventGetSymbol(eventKindType, { zone, element });
                                    return <Icon icon={icon} key={`${zone
                                        } /${element}`}></Icon >;
                                })
                            }</div >
                        </IonItem >
                            : null
                    }
                </section >
            </Popup >
        </Marker >
        <Marker
            position={[
                Number(systemPlanX!) - labelOffsetX,
                Number(systemPlanY!) - labelOffsetY
            ]}
            icon={dIcon}
        />

    </>;
};

export default Element;