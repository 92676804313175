import
{
    IonButton,
    IonCheckbox,
    IonIcon,
    IonItem,
    IonLabel,
    IonList
} from "@ionic/react";

import
{
    Point as GeoJSONPoint,
    Polygon as GeoJSONPolygon
} from 'geojson';

import
{
    GoogleMap
} from '@react-google-maps/api';

import React, {
    useRef,
    useState
} from "react";

import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    closeCircle,
    locateOutline
} from "ionicons/icons";

import
{
    getGeoLocation
} from "../../GeoLocation/GeoLocation";

import
{
    Props
} from "./types";

import
{
    GoogleMapScriptLoader,
    useGoogleMapScriptLoader
} from "../../GoogleScriptLoader";

const mapStyle = {
    width: '100%',
    paddingTop: '75%'
};

const defaultCenter = { lat: 48, lng: 16 };

const transformPoint = (point: GeoJSONPoint): google.maps.LatLngLiteral =>
{
    return { lat: point.coordinates[1], lng: point.coordinates[0] };
};

const transformPolygon = (polygon: GeoJSONPolygon): google.maps.LatLngLiteral[] =>
{
    return polygon.coordinates[0].map(c => ({ lat: c[1], lng: c[0] }));
};

const pathToLatLng = (polygon: google.maps.Polygon) =>
{
    return polygon.getPath().getArray().map(latLng => [latLng.lng(), latLng.lat()]);
};

const polygonToGeoJSON = (polygon: google.maps.Polygon): GeoJSONPolygon =>
{
    const coordinates = [pathToLatLng(polygon)];

    return {
        type: "Polygon",
        coordinates
    };
};

const markerToGeoJSON = (marker: google.maps.Marker): GeoJSONPoint =>
{
    const position = marker.getPosition()!;
    const coordinates = [position.lng(), position.lat()];

    return {
        type: "Point",
        coordinates
    };
};

const addressComponentsMap = {
    'street_number': 'street_number',
    'route': 'street',
    'locality': 'city',
    'country': 'country',
    'postal_code': 'zip'
};

const extractAddressDetails = (address: google.maps.places.PlaceResult) =>
{
    const components = address.address_components || [];
    const parts: Record<string, string> = {};

    for (const component of components) {

        const type = component.types[0] as keyof typeof addressComponentsMap;
        const mappedType = addressComponentsMap[type] || undefined;
        if (mappedType) parts[mappedType] = component.short_name;

    }

    return {
        'street': `${parts.street || ''} ${parts.street_number || ''}`,
        'zip': `${parts.zip || ''}`,
        'city': `${parts.city || ''}`,
        'country': `${parts.country || ''}`,
    };

};

const GPSArea: React.FC<Props> = ({
    area,
    position,
    onChange
}) =>
{
    const { translate: t } = useL10n();

    const saveButton = useRef<HTMLIonButtonElement>(null);
    const deleteButton = useRef<HTMLIonButtonElement>(null);
    const locateButton = useRef<HTMLIonButtonElement>(null);

    const autoCompleteInput = useRef<HTMLInputElement>(null);
    const addressInformation = useRef<HTMLIonListElement>(null);

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>();
    const [marker, setMarker] = useState<google.maps.Marker | null>(null);
    const [geoPosition, setGeoPosition] = useState<google.maps.Marker | null>(null);
    const [accuracy, setAccuracy] = useState<google.maps.Circle | null>(null);
    const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(null);

    const [address, setAddress] = useState<google.maps.places.PlaceResult | null>(null);
    const [applyAddress, setApplyAddress] = useState<boolean>(true);

    const [mapType, setMapType] = useState<string>('satellite');

    const { isLoaded } = useGoogleMapScriptLoader();

    const handleView = () =>
    {
        if (!map) return;

        const bounds = new google.maps.LatLngBounds();

        if (marker) {
            marker.setMap(map);
            const latLng = marker.getPosition();
            if (latLng) bounds.extend(latLng);
        }

        if (polygon) {
            polygon.setMap(map);
            bounds.union(getBounds(polygon));
        }

        if (!bounds.isEmpty()) {
            map.fitBounds(bounds);
        }
    };

    const createItems = () =>
    {
        if (!marker) {
            const _marker = new google.maps.Marker();
            _marker.setDraggable(true);
            if (position) _marker.setPosition(transformPoint(position));

            setMarker(_marker);
        }

        if (typeof polygon === 'undefined' && area) {
            const _polygon = new google.maps.Polygon();
            _polygon.setEditable(true);
            _polygon.setPath(transformPolygon(area));
            setPolygon(_polygon);
        }

        if (!geoPosition) {
            const _geoPosition = new google.maps.Marker({
                draggable: false,
                clickable: false,
                icon: {

                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 5,
                    fillColor: "#008BEF",
                    fillOpacity: 0.8,
                    strokeColor: "#fff",
                    strokeWeight: 2
                }
            });
            setGeoPosition(_geoPosition);
        }

        if (!accuracy) {
            const _accuracy = new google.maps.Circle({
                fillColor: "#008BEF",
                fillOpacity: 0.1,
                strokeColor: "#008BEF",
                strokeWeight: 1
            });
            setAccuracy(_accuracy);
        }
    };

    const createDrawingManager = () =>
    {
        if (!drawingManager) {

            const drawingManager = new google.maps.drawing.DrawingManager({
                polygonOptions: { editable: true },
                markerOptions: { draggable: true }
            });

            drawingManager.addListener('polygoncomplete', setPolygon);
            drawingManager.addListener('markercomplete', setMarker);

            setDrawingManager(drawingManager);
        }
    };

    const setModesAllowed = () =>
    {
        if (!map || !drawingManager) return;
        const modes: google.maps.drawing.OverlayType[] = [];

        if (!position && !marker) modes.push(google.maps.drawing.OverlayType.MARKER);
        if ((!area && typeof polygon === 'undefined') || polygon === null) modes.push(google.maps.drawing.OverlayType.POLYGON);

        if (modes.length) {
            drawingManager.setOptions({
                "drawingControlOptions": {
                    "position": google.maps.ControlPosition.TOP_LEFT,
                    "drawingModes": modes,
                }
            });

            drawingManager?.setMap(map);
        } else {
            drawingManager?.setMap(null);
        }

        // map.controls[google.maps.ControlPosition.TOP_RIGHT].setAt(0, saveButton.current!);
        // if (polygon) map.controls[google.maps.ControlPosition.TOP_RIGHT].setAt(1, deleteButton.current!);
    };

    const showPosition = () =>
    {
        if (!map || !geoPosition || !accuracy) return;
        map.getMapTypeId();
        accuracy.setMap(map);
        geoPosition.setMap(map);

        getGeoLocation().then((position) =>
        {
            const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            geoPosition.setPosition(latLng);
            accuracy.setCenter(latLng);
            accuracy.setRadius(position.coords.accuracy);

            map.fitBounds(accuracy.getBounds()!);

        }).catch((err) =>
        {
            console.log(err);
        });

    };

    const onMapLoad = (map: google.maps.Map) =>
    {
        setMap(map);

        //map.controls[google.maps.ControlPosition.TOP_LEFT].setAt(0, addressInformation.current!);
        map.controls[google.maps.ControlPosition.TOP_LEFT].setAt(0, locateButton.current!);

        const autocomplete = new google.maps.places.Autocomplete(autoCompleteInput.current!);
        autocomplete.addListener("place_changed", () =>
        {
            const place = autocomplete.getPlace();
            const position = place.geometry?.location;

            setAddress(place);
            marker?.setPosition(position);
            console.log(position);

        });
    };

    const getBounds = (polygon: google.maps.Polygon) =>
    {
        const bounds = new google.maps.LatLngBounds();
        for (const latLng of polygon.getPath().getArray()) {
            bounds.extend(latLng);
        }

        return bounds;
    };

    const createStaticMap = () =>
    {
        if (!map || !marker) return "";

        const url = new URL("https://maps.googleapis.com/maps/api/staticmap");
        const position = marker.getPosition();
        const focus = map.getCenter();
        const markers = `${position?.lat()},${position?.lng()}`;
        const center = `${focus?.lat()},${focus?.lng()}`;
        const zoom = map.getZoom();

        // const bounds = map.getBounds();

        // const ne = bounds?.getNorthEast();
        // const sw = bounds?.getSouthWest();

        // const visible = `${ne?.lat()},${ne?.lng()}|${sw?.lat()},${sw?.lng()}`;


        url.searchParams.append("key", "AIzaSyD4KU0gz-fO4hS-BhXUDjy5zYIuAVFHKPI");
        url.searchParams.append("center", center);
        //url.searchParams.append('visible', visible);
        url.searchParams.append("markers", markers);
        url.searchParams.append('size', `600x400`);
        url.searchParams.append('maptype', mapType);
        url.searchParams.append('zoom', `${(zoom || 20) - 1}`);

        if (polygon) {
            const path = pathToLatLng(polygon).map(([lng, lat]) => `${lat},${lng}`);
            path.push(path[0]);
            url.searchParams.append('path', `weight:3|color:0x000000ff|fillcolor:0x00000030|${path.join("|")}`);
        }

        return url.toString();

    };

    const save = () =>
    {
        const GPSArea = polygon ? polygonToGeoJSON(polygon) : null;
        const GPSPosition = marker ? markerToGeoJSON(marker) : null;
        const GPSSnapshotURL = createStaticMap();

        const {
            street = undefined,
            zip = undefined,
            city = undefined,
            country = undefined } = (address && applyAddress) ? extractAddressDetails(address) : {};

        onChange?.({
            GPSPosition, GPSArea, GPSSnapshotURL, street, zip, city, country
        });
    };

    const deleteGPSArea = () =>
    {
        if (polygon) {
            polygon.setMap(null);
            setPolygon(null);
        }
    };

    const clearAddress = () =>
    {
        setAddress(null);
        setApplyAddress(true);
    };

    if (isLoaded) {

        createItems();
        createDrawingManager();
        setModesAllowed();
        handleView();

        return <>
            <IonItem lines="none" style={{ padding: 0 }}>

                <IonList ref={addressInformation} style={{ width: "100%" }}>
                    <IonItem lines="none" hidden={Boolean(address)}>
                        <input ref={autoCompleteInput} className="ion-padding" style={{ width: "100%" }} placeholder={t('Adresse suchen')} />
                    </IonItem>
                    <IonItem lines="none" hidden={!Boolean(address)}>
                        <IonIcon className="ion-padding" icon={closeCircle} onClick={clearAddress}></IonIcon>
                        <IonLabel>{address?.formatted_address}</IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                        <IonCheckbox checked={applyAddress} onIonChange={(e) => setApplyAddress(e.detail.checked)} disabled={!Boolean(address)}>{t('Adresse übernehmen?')}</IonCheckbox>
                    </IonItem>
                </IonList>
            </IonItem >

            <IonItem hidden>
                <IonButton onClick={showPosition} color={'light'} style={{ padding: "10px" }} ref={locateButton}><IonIcon icon={locateOutline}></IonIcon></IonButton>
            </IonItem>

            <IonItem>
                <GoogleMap
                    center={defaultCenter}
                    zoom={12}
                    mapContainerStyle={mapStyle}
                    onMapTypeIdChanged={() =>
                    {
                        setMapType(map?.getMapTypeId() || 'hybrid');
                    }}
                    onLoad={onMapLoad}
                    options={{
                        mapTypeId: mapType,
                        tilt: 0,
                        fullscreenControl: false,
                        mapTypeControlOptions: {
                            position: google.maps.ControlPosition.INLINE_END_BLOCK_START
                        }
                    }}
                >
                </GoogleMap>
            </IonItem >
            <IonItem lines="none">
                <IonButton style={{ width: "50%" }} className="ion-padding" onClick={() => save()} color={'success'} ref={saveButton}>{t('Speichern')}</IonButton>
                <IonButton style={{ width: "50%" }} className="ion-padding" disabled={!Boolean(polygon)} onClick={() => { deleteGPSArea(); }} color={'danger'} ref={deleteButton}>{t('GPS Bereich Löschen')}</IonButton>
            </IonItem>
        </>;

    } else {
        return <></>;
    }
};

const Wrapper: React.FC<Props> = ({
    area,
    position,
    onChange
}) =>
{
    return <GoogleMapScriptLoader>
        <GPSArea area={area} position={position} onChange={onChange} />
    </GoogleMapScriptLoader>;
};

export default Wrapper;

