import React from "react";

import
{
    ListProps
} from "./types";


import
{
    Responsive, useIsMobile
} from "../../Responsive";

import
{
    SystemGroup
} from "../../ReactData/system";

import
{
    Group
} from "./components/Group";

import
{
    Header
} from "./components/Header";

import listStyle from './GruppList.module.scss';

import
{
    NoContent
} from "./components/NoContent";

const GruppList: React.FC<ListProps> = ({
    headers,
    rows,
    onMenu
}) =>
{

    const mobileView = useIsMobile();

    return (
        <div className={`${listStyle.list} ${mobileView ? listStyle.mobile : listStyle.desktop}`}>
            <Responsive desktop>
                <div className={`${listStyle.row} ${listStyle.header} ${listStyle.noExpand}`}>

                    {headers.map((header, index) => (
                        <Header key={index} {...header} />
                    ))}

                </div>
            </Responsive >
            <section className={listStyle.rowContainer}  >
                {!rows?.length && (
                    <NoContent />
                )}
                {rows?.map((group: SystemGroup, index: number) => (
                    <React.Fragment key={index}>{group.hidden ? <></> : <Group
                        onMenu={onMenu}
                        headers={headers}
                        row={group}
                    />}</React.Fragment>
                ))}
            </section>
        </div >
    );
};

export default GruppList;
