import React, { useEffect, useMemo, useRef, useState } from "react";


import { System, SystemStatus } from "@ews/react-data";
import { useL10n } from "@ews/react-localization-context";
import { RTMCategory } from "@ews/zlt-events";
import { IonCard, IonCardContent, IonItem, IonText } from "@ionic/react";
import { GoogleMap, GoogleMapProps, InfoWindow } from "@react-google-maps/api";
import { Link } from "react-router-dom";
import { EventPriority, Events, EventType } from "../Event/types";
import { useGoogleMapScriptLoader } from "../GoogleScriptLoader";
import { EventCategoryIcon, StatusColor, SystemStatusIcon } from "../Icons";
import { useNamedRoutes } from "../NamedRoutes";
import style from "./SystemMap.module.scss";
import { SystemListProps } from "./types";


type _System = System & {
    GPSPosition: {
        type: "Point";
        coordinates: number[];
    };
};


const _EventPriority: EventType[] = ["Alarm", "Disablement"];
function getEventPriority(eventKindType: number)
{
    let priority = -1;
    for (const event of _EventPriority) {

        const byte = eventKindType & RTMCategory[event];
        if (byte) {
            const index = _EventPriority.findIndex((e) => e === event);

            if (priority === -1 || priority > index) {
                priority = index;
            }
        }
    }
    return priority;
}
type EventColor = {
    glyphColor?: string,
    background?: string,
    borderColor?: string,
};

function getPinColor(mainColor: string, glyphColor: string)
{
    return {
        glyphColor,
        background: mainColor,
        borderColor: mainColor,
    };
}

function hasBlinkAnimation(eventStatus: number)
{
    return eventStatus && eventStatus & RTMCategory.Fault;
}

function getEventColor(priorityNumber: number): EventColor | null
{

    if (priorityNumber === 0) {
        return getPinColor("red", "darkred");
    }
    else if (priorityNumber === 1) {
        return getPinColor("yellow", "orange");
    }

    return null;
}


const SystemMap: React.FC<SystemListProps> = ({ systems }) =>
{

    const { isLoaded, loadError } = useGoogleMapScriptLoader();

    function getLatLang(GPSPosition: {
        type: "Point";
        coordinates: number[];
    },
    )
    {
        return {
            lat: GPSPosition.coordinates[1],
            lng: GPSPosition.coordinates[0]
        };
    };

    const markers = systems.filter(system => "GPSPosition" in system) as _System[];

    const { translate: t } = useL10n();

    const [mapType, setMapType] = useState<string>('hybrid');
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const savedMarkers = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);

    const [selectedMarker, setSelectedMarker] = useState<_System | null>(null);

    const { generate } = useNamedRoutes();

    const center = useMemo(
        () => (
            markers.length ?
                {
                    lat: markers[0].GPSPosition.coordinates[1],
                    lng: markers[0].GPSPosition.coordinates[0]
                } :
                { lng: 0, lat: 0 }),
        []);

    const option: GoogleMapProps["options"] = useMemo(() => ({
        mapId: "systemlist-map",
        mapTypeId: mapType,
        streetViewControl: false,
        fullscreenControl: false,
        tilt: 0,
    }), []);


    /* ------------------------ markerFactory ----------------------------*/

    function setIsBlinking(system: System, marker: google.maps.marker.AdvancedMarkerElement)
    {
        const isBlinking = hasBlinkAnimation(system.eventStatus);
        if (isBlinking) {
            const markerContent = marker.content as google.maps.marker.AdvancedMarkerElement;
            markerContent.classList?.add(style.blink);
        }
    }

    function setMarkerEvents(
        system: _System,
        marker: google.maps.marker.AdvancedMarkerElement,
    )
    {
        marker.addListener('click', () => setSelectedMarker(system as _System));
    }

    function markerFactory(
        system: _System,
        marker: google.maps.marker.AdvancedMarkerElement
    )
    {
        setIsBlinking(system, marker);
        setMarkerEvents(system, marker);

        return marker;
    }
    function resetMarkers(map: google.maps.Map)
    {
        savedMarkers.current.forEach(savedMarker =>
        {
            const target = (savedMarker as any).targetElement;
            target.remove();
        });
        savedMarkers.current = [];
        if (markers.findIndex((marker) => marker.id === selectedMarker?.id) === -1) setSelectedMarker(null);
        setMarkers(map);
    }

    function setMarkers(map: google.maps.Map)
    {
        for (const _system of markers) {
            const system = _system as _System;
            if ((system?.GPSPosition?.coordinates)) {

                const priority = getEventPriority(system.eventStatus);

                const eventColor = getEventColor(priority);
                const statusColor = StatusColor[SystemStatus[system.status] as keyof typeof StatusColor];
                const pinColor = eventColor ? eventColor : getPinColor(statusColor, "gray");

                const pin = new google.maps.marker.PinElement(pinColor);
                const marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: getLatLang(system.GPSPosition),
                    content: pin.element,
                    title: system.name
                });

                markerFactory(system, marker);
                savedMarkers.current.push(marker);
            }
        }
    }


    /* ------------------------ markerFactory ----------------------------*/

    useEffect(() =>
    {
        if (map) {
            setMarkers(map);
            resetMarkers(map);

        }
    }, [systems]);


    const onMapLoad = (map: google.maps.Map) =>
    {

        setMap(map);

        setMarkers(map);

    };
    if (loadError) return <section
        className={`${style.map} ${style.error}`}
    >
        <IonCard
            color={"secondary"}
            className={style.card}
        >
            <IonCardContent
                className={style.content}
            >
                <IonText
                    color={"danger"}
                    className={style.msg}
                >{t("somthing went wrong, can not load the Map")}</IonText>
            </IonCardContent>
        </IonCard>
    </section >;


    return isLoaded ? <>

        <GoogleMap
            zoom={11}
            center={center}
            options={option}
            mapContainerClassName={style.map}
            onClick={() => setSelectedMarker(null)}
            onLoad={onMapLoad}
            onUnmount={() => setMap(null)}
            onMapTypeIdChanged={() => setMapType(map?.getMapTypeId() || 'hybrid')}
        >
            {
                selectedMarker ? <InfoWindow
                    options={{
                        pixelOffset: new google.maps.Size(0, -35)
                    }}
                    position={getLatLang(selectedMarker.GPSPosition)}
                    onCloseClick={() => setSelectedMarker(null)}
                >
                    <section>
                        <IonItem lines="full">
                            <span slot="start">
                                <SystemStatusIcon status={selectedMarker.status} />
                            </span>
                            <Link
                                slot="end"
                                to={generate("system:events", { id: selectedMarker.id })}
                            >
                                {selectedMarker.name}
                            </Link>
                        </IonItem>
                        {selectedMarker.eventStatus ? <IonItem lines="full">
                            <div className={style.eventContainer}>
                                {EventPriority.map((e, i) =>
                                {
                                    return (selectedMarker.eventStatus & Events[e]) ?
                                        <span
                                            key={i}
                                            className={style.eventItem}
                                        >
                                            <EventCategoryIcon category={e} />
                                        </span>
                                        :
                                        <React.Fragment key={i} />;
                                })}
                            </div>
                        </IonItem> : null}
                    </section>
                </InfoWindow> : null
            }
        </GoogleMap >
    </> : <></>;
};

export default SystemMap;