import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    SystemRTM,
    SystemStatus
} from "@ews/react-data";

import
{
    AuthorizationLevel,
    deviceType,
    eventDetails,
    eventGetSymbol,
    faultsDetails,
    formatLogicalAddress,
    helpText,
    ioText,
    isGroup,
    isValidDeviceNumber,
    loopType,
    slotIdentifier,
    timestampToDate
} from "@ews/zlt-events";

import { IonCard, IonItem, IonList, IonModal, IonPopover, IonText, IonTitle } from "@ionic/react";

import { useIsMobile } from "../../Responsive";

import Icon, { IconName } from '@ews/react-icons';

import React, { ReactNode, useRef, useState } from "react";
import { useAuthorization } from "../../Authorization";
import { MenuComponent } from "../../Group/GruppList/components/Menu";
import { PositionSide } from "../../List/types";
import Operate, { OperateTarget, validOperations } from "../../Navigation/Sidebar/Operate";
import { SystemElement, SystemGroup } from "../../ReactData/system";
import { useCurrentSystemStatus } from "../../System/SystemStatus";
import { ReactClickEvent } from "../../types";
import eventItemStyle from './EventItem.module.scss';

import LongPress from "../../LongPress";

type EventItemNewProps = {
    rtm: SystemRTM[];
    title: string;
};

function maxScreenHeight(height: number, y: number, side: PositionSide, margin: number = 10): number
{
    return (side === "bottom" ? (height - y) : y) - margin;
}

function pxToPercent(childSize: number, parentSize: number): number
{
    return Math.round((childSize / parentSize) * 100);
}

type EventDetailsProps = {
    event: SystemRTM,
    authorizationLevel: AuthorizationLevel;
};

const EventDetails: React.FC<EventDetailsProps> = ({
    event,
    authorizationLevel

}) =>
{
    const { translate: t } = useL10n();
    const [expand, setExpand] = useState(false);
    const { eventKindType,
        element, zone,
        panelNumber, panelName, isNetwork,
        elementText, groupText,
        planText, alarmText,
        backplaneNumber, busIdentifier, softwareDeviceType,
        loopElement, posLoopElement, mainAdress, subAddress, adressType,
        errorCode, measurement
    } = event;

    //const details = eventDetails(eventKindType!);
    const helpTexts = helpText(eventKindType, errorCode);
    //const eventCategory = details.filter;
    const slotNumber = slotIdentifier(backplaneNumber, busIdentifier); //getSlotNumber(backplaneNumber, busIdentifier);
    const measureText = faultsDetails(measurement) || {};
    const errorText = faultsDetails(errorCode) || {};

    const isGroupEvent = isGroup({
        zone: zone!,
        element: element!
    });

    //console.log(event);

    return <>
        <div>
            <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{groupText}</IonText>
        </div>
        {!isGroupEvent &&
            <div>
                <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{elementText}</IonText>
            </div>
        }
        {!!expand &&
            <>
                {authorizationLevel > AuthorizationLevel.LEVEL1 &&
                    <>
                        {isNetwork &&
                            <div>
                                <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t('TLN:')} {panelNumber}{panelName && ', '}{panelName}</IonText>
                            </div>
                        }

                        {authorizationLevel > AuthorizationLevel.LEVEL2 &&
                            <>
                                <div>
                                    <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>
                                        {slotNumber} {softwareDeviceType && <>{t(deviceType(softwareDeviceType))}-x</>}
                                        <>
                                            <span>, </span>
                                            {isValidDeviceNumber(loopElement || 0) ? <>
                                                {t('[SID_DETAILS_POS]')}: {posLoopElement},<span> </span>
                                                {t('[SID_DETAILS_ADDR]')}: {mainAdress},<span> </span>
                                                {t('[SID_DETAILS_SUB]')}: {subAddress}
                                            </> : <>
                                                {(softwareDeviceType && isValidDeviceNumber(adressType || 0)) && t(ioText(softwareDeviceType, adressType, mainAdress))}
                                            </>
                                            }
                                        </>
                                    </IonText>
                                </div>
                                {isValidDeviceNumber(loopElement || 0) && <div>
                                    <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t(loopType(loopElement))}</IonText>
                                </div>}
                            </>
                        }

                        {(measureText.translationKey || errorText.translationKey) &&
                            <div>
                                {measureText.translationKey && <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t("[SID_DETAILS_DET_VAL]")}: {t(measureText.translationKey)}</IonText>}
                                {(measureText.translationKey && errorText.translationKey) && <>, </>}
                                {errorText.translationKey && <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t(errorText.translationKey || "")}</IonText>}
                            </div>
                        }
                    </>
                }
                <>{authorizationLevel < AuthorizationLevel.LEVEL2 &&
                    <>
                        {planText &&
                            <div>
                                <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{planText}</IonText>
                            </div>
                        }
                        {alarmText &&
                            <div>
                                <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{alarmText}</IonText>
                            </div>
                        }
                        {helpTexts &&
                            <>
                                <div>
                                    <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t(helpTexts.helpTextLine1)}</IonText>
                                </div>
                                <div>
                                    <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t(helpTexts.helpTextLine2)}</IonText>
                                </div>
                                <div>
                                    <IonText color={'medium'} className={eventItemStyle["event-fontsize-title"]}>{t(helpTexts.helpTextLine3)}</IonText>
                                </div>
                            </>
                        }
                    </>
                }</>
            </>
        }
        <div style={{ paddingBottom: ".5em" }} className={eventItemStyle["align-right"]} onClick={() => setExpand(!expand)}>
            <IonText color={'medium'} >{t(expand ? 'Hide Details' : 'Show details')}</IonText>
        </div>
    </>;

};


const EventItem: React.FC<EventItemNewProps> = ({ rtm, title }) =>
{
    const mobileView = useIsMobile();
    const { translate: t, formatDate } = useL10n();

    rtm.sort((l, r) => l.timestamp! - r.timestamp!);

    const { authorizationLevel } = useAuthorization();
    const systemStatus = useCurrentSystemStatus();

    const ContextMenuElement = mobileView ? IonModal : IonPopover;
    const [ContextMenu, setContextMenu] = useState<{ contextMenu?: ReactNode, event?: ReactClickEvent; }>({});

    const contextDirection = useRef<PositionSide>("bottom");
    const contextMaxHeight = useRef<number | null>(1050);

    const presentOptions = (target: SystemElement | SystemGroup, e?: ReactClickEvent) =>
    {
        const contextMenu = <Operate
            target={target}
            onMenuToggle={dismissOptions}
        />;

        const { clientY, view } = e!.nativeEvent as PointerEvent;
        const screenHeight = view?.innerHeight || 0;
        const heightPercent = pxToPercent(clientY, screenHeight);
        const side = heightPercent >= 70 ? "top" : "bottom";

        if (e?.cancelable) e?.preventDefault();
        setContextMenu({ contextMenu, event: e });

        contextDirection.current = side as PositionSide;
        contextMaxHeight.current = maxScreenHeight(screenHeight, clientY, side as PositionSide);
    };

    const dismissOptions = () =>
    {
        setContextMenu({});
    };

    return <>
        <ContextMenuElement
            reference="event"
            side={contextDirection.current}
            event={ContextMenu.event?.nativeEvent}
            initialBreakpoint={mobileView ? .6 : undefined}
            // className={mobileView ? listStyle.menu : ''}
            isOpen={Boolean(ContextMenu.contextMenu)}
            onDidDismiss={dismissOptions}
            keepContentsMounted={true}
            style={{ alignItems: "start", "--max-height": `${contextMaxHeight.current}px `, }}
        >
            {ContextMenu.contextMenu}
        </ContextMenuElement>
        <IonList lines={mobileView ? "full" : 'none'} className={eventItemStyle['event-main-list-container']}>
            <IonItem className={eventItemStyle["list-title-container"]}>
                <IonTitle color='primary'>{title} [{rtm.length}]</IonTitle>
            </IonItem>
            <IonList lines="full" className={eventItemStyle[`event-list-container-${mobileView ? 'mobile' : 'web'}`]}>

                {rtm.map((event: SystemRTM, index: number) =>
                {

                    const { eventKindType, timestamp, zone, element, panelNumber } = event;
                    const { translationKey } = eventDetails(eventKindType!);
                    const symbol = eventGetSymbol(eventKindType!, {
                        zone: zone!,
                        element: element!
                    });

                    const operateTarget = !isValidDeviceNumber(event.element) ? {
                        systemId: event.systemId!,
                        id: event.zone!,
                        properties1: event.properties1,
                        numberGroup: event.numberGroup!,
                        rtm: [event]
                    } : {
                        systemId: event.systemId!,
                        id: event.element!,
                        groupId: event.zone!,
                        numberGroup: event.numberGroup!,
                        properties1: event.properties1,
                        rtm: [event]
                    };

                    const description = t(translationKey);

                    const date = timestampToDate(timestamp!);
                    const label = formatLogicalAddress(zone!, element!, panelNumber!);


                    /*let el = `${element}`;
    
                    if (element < 10) el = `00${element}`;
                    else if (element >= 10 && element < 100) el = `0${element}`;
                    */

                    const hasOperations = validOperations(authorizationLevel, operateTarget as OperateTarget).length;
                    const onMenu = (e?: ReactClickEvent) =>
                    {
                        if (hasOperations && systemStatus === SystemStatus.ONLINE) presentOptions(operateTarget as OperateTarget, e);
                    };

                    return (<IonItem key={'event-item-' + index} className={eventItemStyle['event-container']}>


                        <IonCard {...LongPress(onMenu)} onContextMenu={onMenu} slot="start" className={eventItemStyle["event-card-start"]}>

                            <IonText className={eventItemStyle["event-fontsize-title"]}>{`${label}`}</IonText>

                            {!mobileView && hasOperations && systemStatus === SystemStatus.ONLINE ? (
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <MenuComponent onClick={onMenu} />
                                    <div className={eventItemStyle["event-icon-wrapper"]}>
                                        <Icon height={30} width={30} icon={symbol as IconName} />
                                    </div>
                                </div>) : (<div className={eventItemStyle["event-icon-wrapper"]}>
                                    <Icon height={30} width={30} icon={symbol as IconName} />
                                </div>)}
                        </IonCard>

                        <div style={{ width: "100%", height: "100%" }} {...LongPress(onMenu)} onContextMenu={onMenu}>
                            <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: 1, display: "flex" }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <IonText>{t(description)}</IonText>
                                        <EventDetails event={event} authorizationLevel={authorizationLevel} />
                                    </div>
                                    <div style={{ flexGrow: 0 }}>
                                        <div className={eventItemStyle["align-right"]}>
                                            <IonText>{formatDate(date, { "timezone": 'UTC', "dateStyle": "medium" })}</IonText>
                                        </div>
                                        <div className={eventItemStyle["align-right"]}>
                                            <IonText>{formatDate(date, { "timeZone": 'UTC', "timeStyle": "medium" })}</IonText>
                                        </div>
                                    </div>
                                </div>
                                {mobileView && <div style={{ width: "2em", display: "flex", alignItems: "top", paddingTop: ".5em", justifyContent: "end" }}>
                                    {hasOperations && systemStatus === SystemStatus.ONLINE ?
                                        <MenuComponent onClick={onMenu} /> : <></>
                                    }
                                </div>}

                            </div>
                        </div>

                    </IonItem>);
                })}

            </IonList>
        </IonList >
    </>;
};


export { EventItem };
