import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { scan } from "ionicons/icons";
import { useEffect } from "react";
import { FullscreenProps } from "./types";
import { useIsMobile } from "../Responsive";


export const isFullscreenEnabled = document.fullscreenEnabled;
export const isFullscreenView = Boolean(document.fullscreenElement);

export const getFullscreenElement = () => document.fullscreenElement;


const Fullscreen: React.FC<FullscreenProps> = ({ children, }) =>
{

    const mobileView = useIsMobile();


    //const [isFullscreen, setIsFullscreen] = useState(isFullscreenView);

    useEffect(() =>
    {

        if (mobileView) return;

        function onFullscreenChange()
        {
            console.log('onFullscreenChange');
            //setIsFullscreen(Boolean(document.fullscreenElement));
        }
        function onFullscreenError(error: any)
        {
            console.log(error);
        }
        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () =>
        {
            document.removeEventListener('fullscreenchange', onFullscreenChange);
            document.removeEventListener('fullscreenerror', onFullscreenError);
        };
    }, []);

    const toggleFullscreen = () =>
    {
        if (getFullscreenElement()) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen().catch(console.log);
        }
    };

    return mobileView ? <>{children}</> : <span>
        <IonFab
            vertical="bottom"
            horizontal="start"
        >
            <IonFabButton
                onClick={() =>
                {
                    toggleFullscreen();
                }}>
                <IonIcon icon={scan}>
                </IonIcon>
            </IonFabButton>
        </IonFab>
        {children}
    </span>;
};

export default Fullscreen;