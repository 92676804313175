
type Event = {
    "blink": boolean;
};

type Device = { [key: number]: Event; } & { name: string; };

export type Devices = { [key: string]: Device; };

export const deviceList: Devices =
{
    "ON_RM": {
        "name": "Rauchmelder",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "21": {
            "blink": true
        },
        "13": {
            "blink": false
        },
        "22": {
            "blink": false
        },
        "23": {
            "blink": false
        },
        "24": {
            "blink": false
        },
        "14": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "15": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_WM": {
        "name": "Temperaturmelder",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "21": {
            "blink": true
        },
        "13": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_FLM": {
        "name": "Flammenm.",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_RMZWB": {
        "name": "Rauchm.Zw.boden",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_RMZWD": {
        "name": "Rauchm.Zw.decke",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_HFM": {
        "name": "HFM",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_LLM": {
        "name": "Lüft. Leitungs. melder",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_RAS": {
        "name": "RAS",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "ON_LRM": {
        "name": "linearerRauchm.",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "BMZ": {
        "name": "BMZ",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        },
        "80": {
            "blink": false
        },
        "81": {
            "blink": false
        }
    },
    "SG": {
        "name": "SG",
        "0": {
            "blink": false
        }
    },
    "FBF": {
        "name": "FBF",
        "0": {
            "blink": false
        }
    },
    "ABF": {
        "name": "ABF",
        "0": {
            "blink": false
        }
    },
    "FSS": {
        "name": "FSS",
        "0": {
            "blink": false
        }
    },
    "RWA": {
        "name": "RWA",
        "0": {
            "blink": false
        },
        "67": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        }
    },
    "Siren_1": {
        "name": "Sirene_1",
        "0": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        }
    },
    "Siren_2": {
        "name": "Sirene_2(ähnlichVdS)",
        "0": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        }
    },
    "Flash": {
        "name": "Blitzleuchte",
        "0": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        }
    },
    "Strg_2": {
        "name": "Strg",
        "0": {
            "blink": false
        },
        "67": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        }
    },
    "Strg_1": {
        "name": "Strg mitRelaisspule",
        "0": {
            "blink": false
        },
        "67": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        }
    },
    "Button1": {
        "name": "Button",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "21": {
            "blink": true
        },
        "13": {
            "blink": false
        },
        "22": {
            "blink": false
        },
        "23": {
            "blink": false
        },
        "24": {
            "blink": false
        },
        "14": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "15": {
            "blink": false
        },
        "6": {
            "blink": false
        },
        "60": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "70": {
            "blink": false
        },
        "71": {
            "blink": false
        },
        "72": {
            "blink": true
        },
        "73": {
            "blink": false
        },
        "74": {
            "blink": true
        },
        "75": {
            "blink": false
        },
        "61": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        },
        "67": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        },
        "80": {
            "blink": false
        },
        "81": {
            "blink": false
        },
        "225": {
            "blink": true
        },
        "226": {
            "blink": false
        },
        "227": {
            "blink": false
        },
        "228": {
            "blink": false
        },
        "229": {
            "blink": false
        },
        "230": {
            "blink": false
        },
        "231": {
            "blink": false
        },
        "232": {
            "blink": false
        },
        "233": {
            "blink": false
        },
        "234": {
            "blink": false
        },
        "236": {
            "blink": false
        },
        "237": {
            "blink": false
        },
        "238": {
            "blink": false
        },
        "239": {
            "blink": true
        },
        "240": {
            "blink": true
        },
        "241": {
            "blink": true
        },
        "242": {
            "blink": true
        },
        "243": {
            "blink": true
        },
        "244": {
            "blink": true
        },
        "245": {
            "blink": true
        },
        "246": {
            "blink": true
        },
        "247": {
            "blink": true
        },
        "248": {
            "blink": true
        },
        "251": {
            "blink": false
        },
        "252": {
            "blink": false
        },
        "256": {
            "blink": false
        },
        "257": {
            "blink": false
        },
        "258": {
            "blink": false
        },
        "259": {
            "blink": false
        },
        "263": {
            "blink": false
        },
        "264": {
            "blink": true
        },
        "267": {
            "blink": true
        },
        "268": {
            "blink": true
        },
        "270": {
            "blink": false
        },
        "271": {
            "blink": false
        },
        "273": {
            "blink": true
        }
    },
    "LED": {
        "name": "LED",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "21": {
            "blink": true
        },
        "13": {
            "blink": false
        },
        "22": {
            "blink": false
        },
        "23": {
            "blink": false
        },
        "24": {
            "blink": false
        },
        "14": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "15": {
            "blink": false
        },
        "6": {
            "blink": false
        },
        "60": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "70": {
            "blink": false
        },
        "71": {
            "blink": false
        },
        "72": {
            "blink": true
        },
        "73": {
            "blink": false
        },
        "74": {
            "blink": true
        },
        "75": {
            "blink": false
        },
        "61": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        },
        "67": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        },
        "80": {
            "blink": false
        },
        "81": {
            "blink": false
        },
        "225": {
            "blink": true
        },
        "226": {
            "blink": false
        },
        "227": {
            "blink": false
        },
        "228": {
            "blink": false
        },
        "229": {
            "blink": false
        },
        "230": {
            "blink": false
        },
        "231": {
            "blink": false
        },
        "232": {
            "blink": false
        },
        "233": {
            "blink": false
        },
        "234": {
            "blink": false
        },
        "236": {
            "blink": false
        },
        "237": {
            "blink": false
        },
        "238": {
            "blink": false
        },
        "239": {
            "blink": true
        },
        "240": {
            "blink": true
        },
        "241": {
            "blink": true
        },
        "242": {
            "blink": true
        },
        "243": {
            "blink": true
        },
        "244": {
            "blink": true
        },
        "245": {
            "blink": true
        },
        "246": {
            "blink": true
        },
        "247": {
            "blink": true
        },
        "248": {
            "blink": true
        },
        "251": {
            "blink": false
        },
        "252": {
            "blink": false
        },
        "256": {
            "blink": false
        },
        "257": {
            "blink": false
        },
        "258": {
            "blink": false
        },
        "259": {
            "blink": false
        },
        "263": {
            "blink": false
        },
        "264": {
            "blink": true
        },
        "267": {
            "blink": true
        },
        "268": {
            "blink": true
        },
        "270": {
            "blink": false
        },
        "271": {
            "blink": false
        },
        "273": {
            "blink": true
        }
    },
    "UeE_T-N": {
        "name": "UeE Tag_0 = N = bl_71 = T = ge",
        "0": {
            "blink": false
        },
        "71": {
            "blink": false
        }
    },
    "UeE_Erkund": {
        "name": "UeE Erkund._0 = grau_72 = gelb",
        "0": {
            "blink": false
        },
        "72": {
            "blink": true
        }
    },
    "UeE_Button": {
        "name": "UeE Button",
        "0": {
            "blink": false
        },
        "60": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "70": {
            "blink": false
        },
        "71": {
            "blink": false
        },
        "72": {
            "blink": true
        },
        "73": {
            "blink": false
        },
        "74": {
            "blink": true
        },
        "75": {
            "blink": false
        }
    },
    "VdS_ORM": {
        "name": "VdS_ORM",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_ORMZWD": {
        "name": "VdS_ORMZwi. decke",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_ORMDB": {
        "name": "VdS_ORMDoppelboden",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_WMDiff": {
        "name": "VdS_WMDiff",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_WMMax": {
        "name": "VdS_WMMax",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_HFM": {
        "name": "VdS_HFM",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_ARM": {
        "name": "VdS_ARM",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_FLMIR": {
        "name": "VdS_FLM IR",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_FLM-UV": {
        "name": "VdS_FLM UV",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_FLM-UV-IR": {
        "name": "VdS_FLM UV /IR",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_IRM": {
        "name": "VdS_IRM",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_LKM": {
        "name": "VdS_LKM",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_LRM-Empf": {
        "name": "VdS_LM Empfänger",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_LRM-Send-Empf": {
        "name": "VdS_LM SenderEmpfänger",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_LRM-Send": {
        "name": "VdS_LM Sender",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_LWM-Diff": {
        "name": "VdS_LWM Diff",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_LWM-Max": {
        "name": "VdS_LWM Max",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_MMeld": {
        "name": "VdS_Multi Melder",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "VdS_NAM": {
        "name": "VdS_Nichtautom Melder",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "6": {
            "blink": false
        }
    },
    "Button2": {
        "name": "VdS_Quadrat",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "21": {
            "blink": true
        },
        "13": {
            "blink": false
        },
        "22": {
            "blink": false
        },
        "23": {
            "blink": false
        },
        "24": {
            "blink": false
        },
        "14": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "15": {
            "blink": false
        },
        "6": {
            "blink": false
        },
        "60": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "70": {
            "blink": false
        },
        "71": {
            "blink": false
        },
        "72": {
            "blink": true
        },
        "73": {
            "blink": false
        },
        "74": {
            "blink": true
        },
        "75": {
            "blink": false
        },
        "61": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        },
        "67": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        },
        "80": {
            "blink": false
        },
        "81": {
            "blink": false
        },
        "225": {
            "blink": true
        },
        "226": {
            "blink": false
        },
        "227": {
            "blink": false
        },
        "228": {
            "blink": false
        },
        "229": {
            "blink": false
        },
        "230": {
            "blink": false
        },
        "231": {
            "blink": false
        },
        "232": {
            "blink": false
        },
        "233": {
            "blink": false
        },
        "234": {
            "blink": false
        },
        "236": {
            "blink": false
        },
        "237": {
            "blink": false
        },
        "238": {
            "blink": false
        },
        "239": {
            "blink": true
        },
        "240": {
            "blink": true
        },
        "241": {
            "blink": true
        },
        "242": {
            "blink": true
        },
        "243": {
            "blink": true
        },
        "244": {
            "blink": true
        },
        "245": {
            "blink": true
        },
        "246": {
            "blink": true
        },
        "247": {
            "blink": true
        },
        "248": {
            "blink": true
        },
        "251": {
            "blink": false
        },
        "252": {
            "blink": false
        },
        "256": {
            "blink": false
        },
        "257": {
            "blink": false
        },
        "258": {
            "blink": false
        },
        "259": {
            "blink": false
        },
        "263": {
            "blink": false
        },
        "264": {
            "blink": true
        },
        "267": {
            "blink": true
        },
        "268": {
            "blink": true
        },
        "270": {
            "blink": false
        },
        "271": {
            "blink": false
        },
        "273": {
            "blink": true
        }
    },
    "Button2_Symb": {
        "name": "VdS_Quadrat mit Symbolen",
        "0": {
            "blink": false
        },
        "3": {
            "blink": false
        },
        "1": {
            "blink": false
        },
        "2": {
            "blink": false
        },
        "30": {
            "blink": true
        },
        "31": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "50": {
            "blink": false
        },
        "11": {
            "blink": false
        },
        "51": {
            "blink": false
        },
        "42": {
            "blink": false
        },
        "43": {
            "blink": false
        },
        "4": {
            "blink": false
        },
        "44": {
            "blink": false
        },
        "45": {
            "blink": false
        },
        "5": {
            "blink": false
        },
        "12": {
            "blink": false
        },
        "7": {
            "blink": false
        },
        "21": {
            "blink": true
        },
        "13": {
            "blink": false
        },
        "22": {
            "blink": false
        },
        "23": {
            "blink": false
        },
        "24": {
            "blink": false
        },
        "14": {
            "blink": false
        },
        "25": {
            "blink": false
        },
        "26": {
            "blink": false
        },
        "15": {
            "blink": false
        },
        "6": {
            "blink": false
        },
        "60": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "70": {
            "blink": false
        },
        "71": {
            "blink": false
        },
        "72": {
            "blink": true
        },
        "73": {
            "blink": false
        },
        "74": {
            "blink": true
        },
        "75": {
            "blink": false
        },
        "61": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        },
        "67": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        },
        "80": {
            "blink": false
        },
        "81": {
            "blink": false
        },
        "225": {
            "blink": true
        },
        "226": {
            "blink": false
        },
        "227": {
            "blink": false
        },
        "228": {
            "blink": false
        },
        "229": {
            "blink": false
        },
        "230": {
            "blink": false
        },
        "231": {
            "blink": false
        },
        "232": {
            "blink": false
        },
        "233": {
            "blink": false
        },
        "234": {
            "blink": false
        },
        "236": {
            "blink": false
        },
        "237": {
            "blink": false
        },
        "238": {
            "blink": false
        },
        "239": {
            "blink": true
        },
        "240": {
            "blink": true
        },
        "241": {
            "blink": true
        },
        "242": {
            "blink": true
        },
        "243": {
            "blink": true
        },
        "244": {
            "blink": true
        },
        "245": {
            "blink": true
        },
        "246": {
            "blink": true
        },
        "247": {
            "blink": true
        },
        "248": {
            "blink": true
        },
        "251": {
            "blink": false
        },
        "252": {
            "blink": false
        },
        "256": {
            "blink": false
        },
        "257": {
            "blink": false
        },
        "258": {
            "blink": false
        },
        "259": {
            "blink": false
        },
        "263": {
            "blink": false
        },
        "264": {
            "blink": true
        },
        "267": {
            "blink": true
        },
        "268": {
            "blink": true
        },
        "270": {
            "blink": false
        },
        "271": {
            "blink": false
        },
        "273": {
            "blink": true
        }
    },
    "VdS_Blitzleuchte": {
        "name": "VdS opt. Signalgeber",
        "0": {
            "blink": false
        },
        "62": {
            "blink": false
        },
        "63": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "64": {
            "blink": true
        },
        "65": {
            "blink": false
        },
        "66": {
            "blink": true
        }
    },
    "VdS_Strg-modul": {
        "name": "VdS Steuermodul",
        "0": {
            "blink": false
        },
        "67": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        }
    },
    "VdS_Tuermag": {
        "name": "VdS Türhaltemagnet",
        "0": {
            "blink": false
        },
        "67": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "41": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "68": {
            "blink": true
        },
        "76": {
            "blink": false
        },
        "77": {
            "blink": false
        }
    },
    "VdS_UeE": {
        "name": "VdS Übertragungsgerät",
        "0": {
            "blink": false
        },
        "60": {
            "blink": false
        },
        "32": {
            "blink": true
        },
        "33": {
            "blink": true
        },
        "40": {
            "blink": false
        },
        "52": {
            "blink": false
        },
        "46": {
            "blink": false
        },
        "70": {
            "blink": false
        },
        "71": {
            "blink": false
        },
        "72": {
            "blink": true
        },
        "73": {
            "blink": false
        },
        "74": {
            "blink": true
        },
        "75": {
            "blink": false
        },
        "61": {
            "blink": false
        }
    }
};